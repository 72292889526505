import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.prototype.$http = axios.create({
  // baseURL: "http://localhost/smp-afbs/public/api",
  // baseURL: "http://192.168.100.17/smp-afbs/public/api",
  baseURL: "https://ppdb.ligno.co.id/api",
});
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    countOfInformationCategory: [],
  },
  mutations: {
    getCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  actions: {
    async getCurrentUser(context) {
      if (!localStorage.getItem("smp-afbs")) {
        context.commit("getCurrentUser", null);
        return null;
      }
      await Vue.prototype.$http
        .get("/user/info/", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("smp-afbs"),
          },
        })
        .then((result) => {
          context.commit("getCurrentUser", result.data);
        })
        .catch(error => {
          // console.log('error');
          localStorage.removeItem("smp-afbs");
          this.$router.push("/login");
        })
    },
  },
  modules: {},
});
