<template>
  <div>
    <div class="footer">
      <div class="">
        <div class="text-h4 q-pa-md">
          <img src="@/assets/img/Logo4.png" alt="logo_big" style="width: 200px; width: 200px;" />
        </div>
        

        <div class="text-h6 text-bold">SMP AL FURQON BOARDING SCHOOL</div>
        <div style="font-size: 16px;" class="text-grey-9">
          Jl. Untung Suropati No.13, Cimone Jaya, Kecamatan Karawaci <br/>
          Kota Tangerang, Banten 15114 | (021) 55784542 | smpafbs.sch.id
        </div>
        <div class="socmed">
          <div class="icon-area"><a href="https://www.facebook.com/infogeospasial/" style="color:  #91D8F7;" target="_blank"><q-icon size="sm" name="fab fa-facebook-f" /></a></div>
          <div class="icon-area"><a href="https://www.instagram.com/infogeospasial/" style="color:  #91D8F7;" target="_blank"><q-icon size="sm" name="fab fa-instagram" /></a></div>
          <div class="icon-area"><a href="https://twitter.com/InfoGeospasial" style="color:  #91D8F7;" target="_blank"><q-icon size="sm" name="fab fa-twitter" /></a></div>
          <div class="icon-area"><a href="https://www.youtube.com/channel/UC45iA5MDqvSe6JRWSt7wvZg" style="color:  #91D8F7;" target="_blank"><q-icon size="sm" name="fab fa-youtube" /></a></div>
        </div>
      </div>
      <div class="q-py-md">
        <div class="text-h5 text-weight-bold q-pl-md q-pb-md">Chat Support</div>
        <div class="contacts row wrap">
          <!-- <div class="contact"><div class="icon-area"><q-icon size="sm" name="fas fa-map-marked-alt" /></div> Jl. Untung Suropati No.13, Cimone Jaya, Kecamatan Karawaci
                                            Kota Tangerang, Banten 15114</div>
          <div class="contact"><div class="icon-area"><q-icon size="sm" name="fas fa-phone-volume" /></div> (021) 55784542</div>
          <div class="contact"><div class="icon-area"><q-icon size="sm" name="far fa-envelope" /></div> smpafbs@gmail.com</div> -->
          <div v-for="(x, i) in contacts" :key="i" class="q-pa-xs" style="">
            <a :href="'https://api.whatsapp.com/send?phone='+ x.nomor +'&text=Assalaamu%27alaikum%20' + x.nama"  target="_blank">
              <img :src="x.icon" :alt="x.icon + i" style="width: 140px;" >
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-note">
      <div class="note">
        	&copy; Copyright {{year}} SMP AFBS. All rights reserved.
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: 'Footer',
  data:function(){
    return{
      year: new Date().getFullYear(),
      contacts:[
        {icon: './img/kontak/jumadiwa.png', nomor: '6285777694354', nama: 'Pak Jumadi'},
        {icon: './img/kontak/dhinawa.png', nomor: '6281331221285', nama: 'Bu Dhina'},
        {icon: './img/kontak/reniwa.png', nomor: '6285692868354', nama: 'Bu Reni'},
        {icon: './img/kontak/ilyaswa.png', nomor: '6289676055125', nama: 'Pak Ilyas'},
        {icon: './img/kontak/awalinwa.png', nomor: '6285885559956', nama: 'Bu Awalin'},
      ]
    }
  },
  computed:{
    winWidth(){
      return this.$q.screen.width
    }
  }
}
</script>

<style lang="scss" scoped>
.footer{
  min-height: 150px; width: 100%; padding: 30px; 
  background-color: #91D8F7; 
  display: flex; justify-content: space-between;
  flex-wrap: wrap;
}
.footer-note{
  background-color: #91D8F7; 
  // background-color: #E3F2F8; 
  padding: 20px 60px; 
  font-size: 12px; color: grey; 
  display: flex; justify-content: center;
  .note{
    text-align: center;
  }
}
.icon-area{
  margin: 0 10px; padding: 5px; color: #91D8F7;
  display: flex; align-items: flex-start; justify-content: center;
}
.footer-title{
  font-size: 1.5em; font-weight: bold; color: #505050;
}
.socmed{
  display: flex;
  padding: 10px 0px;
}
.site-links{
  display: flex; width: 300px; color: grey;
  flex-wrap: wrap;
  .site-link{
    width: 50%; padding: 5px; cursor: pointer; font-size: 16px;
    &:hover{
      color: #0B8CC4;
    }
  }
}
.contacts{max-width: 450px; min-width: 300px;}
.contact{
  display: flex; padding: 5px; font-size: 16px;
  color: grey;
}
.q-icon{
  &:hover{
    color: #2bb8f5;
  }
}

</style>
