import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/galeri',
    name: 'Galeri',
    component: () => import('../views/Galeri.vue')
  },
  {
    path: '/kontak',
    name: 'Kontak',
    component: () => import('../views/Kontak.vue')
  },
  {
    path: '/pendaftaran',
    name: 'Pendaftaran',
    component: () => import('../views/Pendaftaran.vue'),
    children: [
      {
        path: 'konfirmasi',
        name: 'konfirmasi',
        component: () => import('../components/KonfirmasiBayar.vue')
      },
    ]
  },
  {
    path: '/m-pendaftaran',
    name: 'm-Pendaftaran',
    component: () => import('../views/PendaftaranMobile.vue')
  },
  {
    path: '/guru&staff',
    name: 'Guru-Staff',
    component: () => import('../views/StaffGuru.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    children: [
      {
        path: '',
        redirect: 'data-pendaftar'
      },
      {
        path: 'data-pendaftar',
        name: 'data-pendaftar',
        component: () => import('../components/DataPendaftar.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/admin-login',
    name: 'admin-login',
    component: () => import('../views/LoginAdmin.vue')
  },
  {
    path: '/buat-akun',
    name: 'buat-akun',
    component: () => import('../views/BuatAkun.vue')
  },
  {
    path: '/form-final/:nisn',
    name: 'form-final',
    component: () => import('../components/FormFinal.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
  scrollBehavior(to, from, savedPosition) {
    var paths = ["CategoryInfo"] //path name for exception
    // return desired position
    if(!paths.includes(to.name))
    {
      return { x: 0, y: 0 };
    }
  },
})

export default router
