<template>
  <q-layout ><!-- view="lHh Lpr lFf" -->
    <div :class="'header' + headerclass" class="row items-start">
      <div class="flex items-center q-pl-lg">
        <a  target="_blank">
          <img v-if="!headerclass"
            src="@/assets/img/logo-light2.png"
            alt="logo_big"
            style="width: 200px;" class="logo"
          />
          <img v-else
            src="@/assets/img/Logo4.png"
            alt="logo_big"
            style="width: 100px;" class="logo"
          />
        </a>
      </div>
      <div class="menus" v-if="$q.screen.width > 500">
        <div
          :class="'flex flex-center menu' + (active == '' ? 'active' : '')"
          @click="clickMenu('')"
        >
          BERANDA
        </div>
        <div
          :class="
            'flex flex-center menu' + (active == 'galeri' ? 'active' : '')
          "
          @click="clickMenu('galeri')"
        >
          GALERI
        </div>
        <div
          :class="
            'flex flex-center menu' + (active == 'guru&staff' ? 'active' : '')
          "
          @click="clickMenu('guru&staff')"
        >
          GURU &amp; STAFF
        </div>
        <div
          :class="'flex flex-center menu' + (active == 'pendaftaran' ? 'active' : '')"
          @click="clickMenu('pendaftaran')"
          style="margin-right: 30px;"
        >
          PENDAFTARAN
        </div>
        <!-- <div
          :class="'flex flex-center menu' + (active == 'kontak' ? 'active' : '')"
          @click="clickMenu('kontak')"
        >
          KONTAK
        </div> -->
        
        <div class="flex items-center">
          <q-btn-dropdown
            v-if="currentUser"
            unelevated
            rounded
            no-caps
            color="light-blue-5"
            :label="currentUser.username.substring(0, 5) + '..'"
            icon="person"
            style="font-size: 12px;"
          >
            <q-list>
              <q-item clickable v-close-popup @click="toFormFinal">
                <q-item-section>
                  <q-item-label>Download Formulir</q-item-label>
                </q-item-section>
              </q-item>

              <q-item clickable v-close-popup @click="logout">
                <q-item-section>
                  <q-item-label>Logout</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-btn-dropdown
            v-else
            unelevated
            rounded
            no-caps
            color="light-blue-5"
            label="Daftar"
            icon="person"
            style="font-size: 12px;"
          >
            <q-list>
              <q-item clickable v-close-popup @click="clickMenu('buat-akun')">
                <q-item-section>
                  <q-item-label>Daftar</q-item-label>
                </q-item-section>
              </q-item>

              <q-item clickable v-close-popup @click="clickMenu('login')">
                <q-item-section>
                  <q-item-label>Login</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </div>
      <div v-else class="q-pr-lg q-pt-sm">
        <q-icon right size="2em" color="light-blue-5" name="menu" @click="dialog = true" />
      </div>
    </div>

    <q-dialog
      v-model="dialog"
      persistent
      maximized
      transition-show="slide-down"
      transition-hide="slide-up"
    >
      <q-card class="bg-white text-grey-8">
        <q-bar style="height: 90px;" class="bg-blue-1 q-px-lg">
          <div>
            <img
            src="@/assets/img/logo-light2.png"
            alt="logo_big"
            style="width: 60px; width: 60px;"
          />
          </div>
          <q-space />
          <div style="height: 50px;">
            <q-icon right size="1.5em" name="close" v-close-popup/>
          </div>
        </q-bar>

        <q-card-section>
          <q-list class="rounded-borders">
            <q-item clickable v-close-popup @click="clickMenu('')">
              <q-item-section>Beranda</q-item-section>
            </q-item>
            <q-separator/>
            <q-item clickable v-close-popup @click="clickMenu('galeri')">
              <q-item-section>Galeri</q-item-section>
            </q-item>
            <q-separator/>
            <q-item clickable v-close-popup @click="clickMenu('guru&staff')">
              <q-item-section>Guru &amp; Staff</q-item-section>
            </q-item>
            <q-separator/>
            <q-item clickable v-close-popup @click="clickMenu('m-pendaftaran')">
              <q-item-section>Pendaftaran</q-item-section>
            </q-item>
            <!-- <q-separator/>
            <q-item clickable v-close-popup @click="clickMenu('kontak')">
              <q-item-section>Kontak</q-item-section>
            </q-item> -->
          </q-list>
        </q-card-section>
        <q-card-section class="q-pt-xl">
          <div  v-if="currentUser" class="row justify-evenly">
            <q-btn no-caps outline style="min-width: 100px;" color="light-blue-6" label="Download Formulir" @click="toFormFinal" v-close-popup />
            <q-btn no-caps outline style="min-width: 100px;" color="deep-orange-9" label="Logout" @click="logout" v-close-popup />
          </div>
          <div  v-else class="row justify-evenly">
            <q-btn no-caps outline style="min-width: 100px;" color="light-blue-6" label="Buat Akun" @click="clickMenu('buat-akun')" v-close-popup />
            <q-btn no-caps outline style="min-width: 100px;" color="light-blue-6" label="Login" @click="clickMenu('login')" v-close-popup />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="alert">
      <q-card class="q-pa-md">
        <q-card-section>
          Anda belum isi formulir pendaftaran!
        </q-card-section>
        <q-card-sectio class="row justify-center">
          <q-btn v-close-popup flat color="light-blue-6" @click="$router.push('/pendaftaran')" label="isi formulir" />
        </q-card-sectio>
      </q-card>
    </q-dialog>
    
    <q-page-container style="padding-top: 90px;">
      <router-view />
    </q-page-container>

    <Footer />
  </q-layout>
</template>

<script>
import Footer from "@/components/Footer.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "App",
  components: { Footer },
  data() {
    return {
      active: "",
      lastScrollPosition: 0,
      headerclass: "-transparent",

      dialog: false,
      alert: false,
      // currentUser: {}
    };
  },
  mounted() {
    this.getCurrentUser();
    if(this.$route.name != 'Home') {
      this.headerclass = ""
    }

    window.addEventListener("scroll", this.onScroll);

  },
  computed: mapState(["currentUser"]),

  methods: {
    ...mapActions(["getCurrentUser"]),
    toFormFinal(){
      
      this.$http.get('/get_status_pendaftaran/' + this.$store.state.currentUser.username,{} )
        .then (result => {
          let routeData = this.$router.resolve({name: 'form-final', params:{nisn: result.data.nisn}});
          window.open(routeData.href, '_blank');
        })
        .catch(err => {
          console.log('error');
          this.alert = true
        })
    },
    logout() {
      localStorage.removeItem("smp-afbs");
      this.getCurrentUser();
      this.$router.push("/");
    },

    clickMenu(val) {
      this.active = val;
      if (val == "") this.headerclass = "-transparent"
      else this.headerclass = ""

      // console.log(val);

      this.$router.push("/" + val).catch(() => {});
        
    },
    onScroll() {
      // console.log('scroll')
      if (window.pageYOffset < 30 && this.$route.name == 'Home') {
        // console.log('top')
        this.headerclass = "-transparent";
      } else {
        // console.log('bott')
        this.headerclass = "";
      }
    },
    goTo(link){
      this.$router.push(link).catch(()=>{})
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss" >
@import "@/styles/style.scss";
.header,
.header-transparent {
  font-family: "Roboto-regular";
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 4 !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  transform: translate3d(0, 0, 0);
  transition: 1s all ease-out;
  // font-family: "Roboto-bold" !important;
  color: white;
}
.header-transparent{
  // background-color: rgba(128, 215, 255, 0.15);
  // border-bottom: 1px solid rgb(128, 215, 255);
  .menus{
    .menu, .menuactive {
      min-width: 100px; padding: 0px 15px;
      height: 50px;
      &:hover {
        border-bottom: 1px solid #8BD7F9;
        color: #8BD7F9; font-weight: bold;
      }
    }
    .menuactive {
      border-bottom: 1px solid #8BD7F9;
      color: #8BD7F9; font-weight: bold;
    }
  }
}
.header{
  background-color: white;
  color: grey;
  -webkit-box-shadow: 0 6px 4px -4px silver;
  -moz-box-shadow: 0 6px 4px -4px silver;
  box-shadow: 0 6px 4px -4px silver;
}
.menus {
  display: flex;
  user-select: none;
  box-sizing: border-box;
  font-family: "Roboto-bold" !important;
  .menu,.menuactive {
    min-width: 100px; padding: 0px 15px;
    height: 50px;
    &:hover {
      border-bottom: 1px solid #0b8cc4;
      color: #0b8cc4;
    }
  }
  .menuactive {
    border-bottom: 1px solid #0b8cc4;
    color: #0b8cc4;
  }
}
.q-item__section--side > .q-icon {
  font-size: 14px !important;
}
@media screen and (max-width: 500px) {
  .header, .header-transparent {
    padding: 10px;
    align-items: flex-start;
  }
}
.btn-menu .q-btn-dropdown__arrow {
    margin-left: 0px !important;
}
.btn-menu .q-btn__wrapper {
    padding: 4px 0px 4px 10px !important;
}

.logo{
  transform: translate3d(0, 0, 0);
  // transition: 0.5s all;
  align-items: center; align-content: center;
}
</style>
